import React from "react";
import styled from "styled-components";

const CheckBox = styled.input.attrs({ type: "checkbox" })`
  transform: scale(1.1);
  border-radius: 50%;
  border: 0.15rem solid white;
  accent-color: var(--darkGreen);

  &:checked {
    background: rgba(0, 0, 0, 0);
  }
`;

const Label = styled.label`
  margin-left: 1rem;
  width: 100%;
`;

const Flex = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

function CheckBoxInput({ children, ...props }) {
  return (
    <Flex>
      <CheckBox
        id={props.id}
        name={props.name}
        value={props.value}
        {...props}
      />
      <Label htmlFor={props.id}>{children}</Label>
    </Flex>
  );
}

export default CheckBoxInput;
