import React from "react";
import styled from "styled-components";
import { Bars } from "react-loader-spinner";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: var(--loadingAnimationBackgroundColor);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const LoadingAnimator = styled.div`
  color: white;
`;

function LoadingAnimation() {
  return (
    <Wrapper>
      <LoadingAnimator>
        <Bars color="white" />
        <p>Berekenen...</p>
      </LoadingAnimator>
    </Wrapper>
  );
}

export default LoadingAnimation;
