import React from "react";
import DualBlockLayout from "../DualBlockLayout.js";
import TextInput from "../inputs/TextInput.js";
import Button from "../Button.js";
import styled from "styled-components";
import bouwjaar from "../images/bouwjaar.jpg";
import { useState } from "react";
import BaseLayout from "../BaseLayout.js";
import ProgressIndicators from "../ProgressIndicators.js";

const PreviousButton = styled(Button)`
  background-color: var(--lightBlue);
`;

const YearBuildInput = styled(TextInput)`
  width: 8ch;
`;

function YearBuildPage({ switchPage, formData, changeHandler }) {
  const [invalid, setUpdated] = useState("");

  const today = new Date();

  const validateInput = (yearBuild) => {
    if (yearBuild < 1945 || yearBuild > today.getFullYear()) {
      setUpdated("Alleen bouwjaren vanaf 1945 worden meegenomen.");
    } else {
      switchPage(1);
    }
  };

  return (
    <BaseLayout>
      <ProgressIndicators currentStep={2} totalSteps={5} />
      <DualBlockLayout image={bouwjaar}>
        <>
          Welk <b>bouwjaar</b> heeft het <b>woongebouw</b>?
        </>
        <>
          Het bouwjaar geeft inzicht in de toegepaste duurzaamheidsmaatregelen
          bij de bouw van de woning en heeft invloed op de inschatting van het
          te verrichten onderhoud aan het woongebouw. Het minimale bouwjaar is
          1945.
        </>
        <>
          <YearBuildInput
            id="bouwjaar"
            name="yearBuild"
            type="number"
            min={1945}
            max={today.getFullYear()}
            width="8ch"
            onChange={(e) => changeHandler(e)}
            value={formData.yearBuild}
          >
            <>Het bouwjaar van het woongebouw is</>
            <>{invalid}</>
          </YearBuildInput>
        </>
      </DualBlockLayout>
      <>
        <PreviousButton text="VORIGE" onClick={() => switchPage(-1)} />
        <Button
          text="VOLGENDE"
          onClick={() => validateInput(formData.yearBuild)}
        />
      </>
    </BaseLayout>
  );
}

export { YearBuildPage };
