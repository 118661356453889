import React from "react";
import DualBlockLayout from "../DualBlockLayout.js";
import RadioInput from "../inputs/RadioInput.js";
import Tooltip from "../Tooltip.js";
import Button from "../Button.js";
import styled from "styled-components";
import type_woongebouw from "../images/type_woongebouw.jpg";
import BaseLayout from "../BaseLayout.js";
import ProgressIndicators from "../ProgressIndicators.js";

const PreviousButton = styled(Button)`
  background-color: var(--lightBlue);
`;

function TypePage({ switchPage, formData, changeHandler }) {
  return (
    <BaseLayout>
      <ProgressIndicators currentStep={1} totalSteps={5} />
      <DualBlockLayout image={type_woongebouw}>
        <>
          Tot welk <b>type</b> rekent u het <b>woongebouw</b>?
        </>
        <>
          Met type bedoelen we een aantal kenmerken van een gebouw die
          vergelijkbaar zijn. We maken onderscheid uit vier verschillende types.
        </>
        <>
          <RadioInput
            id="galerijflat"
            name="residenceType"
            value="galerij"
            onChange={(e) => changeHandler(e)}
            checked={formData.residenceType === "galerij"}
          >
            Galerijflat&nbsp;&nbsp;
            <Tooltip
              id="galerijTooltip"
              text="Een gebouw waarbij de appartementen vanaf een galerij bereikbaar zijn."
            />
          </RadioInput>
          <br></br>
          <br></br>
          <RadioInput
            id="portiekflat"
            name="residenceType"
            value="portiek"
            onChange={(e) => changeHandler(e)}
            checked={formData.residenceType === "portiek"}
          >
            Portiekflat&nbsp;&nbsp;
            <Tooltip
              id="portiekTooltip"
              text="Een gebouw waarbij de appartementen vanaf een inpandig portiek bereikbaar zijn."
            />
          </RadioInput>
          <br></br>
          <br></br>
          <RadioInput
            id="rijwoningen"
            name="residenceType"
            value="rijwoning"
            onChange={(e) => changeHandler(e)}
            checked={formData.residenceType === "rijwoning"}
          >
            Rijwoningen&nbsp;&nbsp;
            <Tooltip
              id="rijwoningTooltip"
              text="Een eengezinswoning aaneengesloten aan de buurwoningen."
            />
          </RadioInput>
          <br></br>
          <br></br>
          <RadioInput
            id="maisonnettes"
            name="residenceType"
            value="maisonnette"
            onChange={(e) => changeHandler(e)}
            checked={formData.residenceType === "maisonnette"}
          >
            Maisonnettes&nbsp;&nbsp;
            <Tooltip
              id="maisonnetteTooltip"
              text="Een gebouw waarbij appartementen bestaan uit twee bouwlagen."
            />
          </RadioInput>
        </>
      </DualBlockLayout>
      <>
        <PreviousButton text="VORIGE" onClick={() => switchPage(-1)} />
        <Button text="VOLGENDE" onClick={() => switchPage(1)} />
      </>
    </BaseLayout>
  );
}

export { TypePage };
