import React from "react";
import DualBlockLayout from "../DualBlockLayout.js";
import RadioInput from "../inputs/RadioInput.js";
import Button from "../Button.js";
import styled from "styled-components";
import onderhoudsvraag from "../images/onderhoudsvraag.jpg";
import BaseLayout from "../BaseLayout.js";
import ProgressIndicators from "../ProgressIndicators.js";
import { useState } from "react";

const PreviousButton = styled(Button)`
  background-color: var(--lightBlue);
`;

function MaintenanceQuestionPage({ switchPage, changeHandler, callDMCAPI }) {
  const [selected, setSelected] = useState("ntb");

  function checkInput() {
    if (selected === "yes") {
      switchPage(1);
    } else {
      callDMCAPI(10);
    }
  }

  return (
    <BaseLayout>
      <ProgressIndicators currentStep={2} totalSteps={3} />
      <DualBlockLayout image={onderhoudsvraag}>
        <>
          Is er <b>onderhoud</b> dat uitgevoerd moet worden?
        </>
        <>
          Kies <b>Ja</b> indien u inzichtelijk heeft welk bedrag u aan onderhoud
          uit gaat geven voor uw woongebouw. Kies <b>Nee</b> indien u enkel
          rekening wilt houden met verduurzamingskosten. Kies{" "}
          <b>Weet ik niet</b> indien u het niet weet of indien u de
          onderhoudskosten zo wilt laten.
        </>
        <>
          <RadioInput
            id="ja"
            name="estimateMaintenance"
            value="false"
            onChange={(e) => {
              changeHandler(e);
              setSelected("yes");
            }}
            checked={selected === "yes"}
          >
            Ja
          </RadioInput>
          <br></br>
          <br></br>
          <RadioInput
            id="nee"
            name="estimateMaintenance"
            value="false"
            onChange={(e) => {
              changeHandler(e);
              setSelected("no");
            }}
            checked={selected === "no"}
          >
            Nee
          </RadioInput>
          <br></br>
          <br></br>
          <RadioInput
            id="ntb"
            name="estimateMaintenance"
            value="true"
            onChange={(e) => {
              changeHandler(e);
              setSelected("ntb");
            }}
            checked={selected === "ntb"}
          >
            Weet ik niet
          </RadioInput>
        </>
      </DualBlockLayout>
      <>
        <PreviousButton text="VORIGE" onClick={() => switchPage(-1)} />
        <Button text="VOLGENDE" onClick={() => checkInput()} />
      </>
    </BaseLayout>
  );
}

export { MaintenanceQuestionPage };
