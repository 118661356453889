import React from "react";
import Tooltip from "./Tooltip.js";
import styled from "styled-components";

const MeasuresTitle = styled.h3`
  margin: 0;
`;

const MeasuresList = styled.ul`
  padding-inline-start: 20px;
  margin-bottom: 2rem;
  color: white;
`;

export function extractMeasuresFromJSON(list, key) {
  for (let i = 0; i < list.length; i++) {
    let obj = list[i];
    if (obj.type === key) {
      return obj.measures;
    }
  }
  return [];
}

function buildMeasuresComponent(title, tooltip, measures) {
  let li = [];
  measures.forEach((measure) => li.push(<li key={measure}>{measure}</li>));
  if (li.length > 0) {
    return (
      <>
        <MeasuresTitle>
          {title} {tooltip}
        </MeasuresTitle>
        <MeasuresList>{li}</MeasuresList>
      </>
    );
  } else {
    return <></>;
  }
}

// Parse the API response of estimated measures
export function getMeasures(jsonData, type) {
  try {
    let measures;
    switch (type) {
      case "standardSustMeasures":
        measures = extractMeasuresFromJSON(
          jsonData["measures"]["sustainabilityMeasures"],
          "Standaard maatregelen aanpak"
        );
        return buildMeasuresComponent(
          "Standaard maatregelen aanpak",
          <Tooltip
            id="standardSustMeasures"
            text="Maatregelen die in 80% of meer van de projecten op grond waarvan de gemiddelde indicatie is gegeven, zijn toegepast."
          />,
          measures
        );
      case "commonSustMeasures":
        measures = extractMeasuresFromJSON(
          jsonData["measures"]["sustainabilityMeasures"],
          "Veel voorkomende maatregelen"
        );
        return buildMeasuresComponent(
          "Veel voorkomende maatregelen",
          <Tooltip
            id="commonSustMeasures"
            text="Maatregelen die in 50% tot 80% van de projecten op grond waarvan de gemiddelde indicatie is gegeven, zijn toegepast."
          />,
          measures
        );
      case "lessCommonSustMeasures":
        measures = extractMeasuresFromJSON(
          jsonData["measures"]["sustainabilityMeasures"],
          "Regelmatig voorkomende maatregelen"
        );
        return buildMeasuresComponent(
          "Regelmatig voorkomende maatregelen",
          <Tooltip
            id="lessCommonSustMeasures"
            text="Maatregelen die in 35% tot 50% van de projecten op grond waarvan de gemiddelde indicatie is gegeven, zijn toegepast."
          />,
          measures
        );
      case "specificSustMeasures":
        measures = extractMeasuresFromJSON(
          jsonData["measures"]["sustainabilityMeasures"],
          "Specifiek toegepaste maatregelen"
        );
        return buildMeasuresComponent(
          "Specifiek toegepaste maatregelen",
          <Tooltip
            id="specificSustMeasures"
            text="Maatregelen die in 10% tot 35% van de projecten op grond waarvan de gemiddelde indicatie is gegeven, zijn toegepast."
          />,
          measures
        );
      case "c":
        measures = extractMeasuresFromJSON(
          jsonData["measures"]["sustainabilityMeasures"],
          "Zelden tot nooit toegepaste maatregelen"
        );
        return buildMeasuresComponent(
          "Zelden tot nooit toegepaste maatregelen",
          <Tooltip
            id="specificSustMeasures"
            text="Maatregelen die in 10% of minder van de projecten op grond waarvan de gemiddelde indicatie is gegeven, zijn toegepast."
          />,
          measures
        );
      case "standardMaintMeasures":
        measures = extractMeasuresFromJSON(
          jsonData["maintenanceMeasures"]["maintenanceMeasures"],
          "Standaard maatregelen aanpak"
        );
        return buildMeasuresComponent(
          "Standaard maatregelen aanpak",
          <Tooltip
            id="standardMaintMeasures"
            text="Maatregelen die in 80% of meer van de projecten op grond waarvan de gemiddelde indicatie is gegeven, zijn toegepast."
          />,
          measures
        );
      case "commonMaintMeasures":
        measures = extractMeasuresFromJSON(
          jsonData["maintenanceMeasures"]["maintenanceMeasures"],
          "Veel voorkomende maatregelen"
        );
        return buildMeasuresComponent(
          "Veel voorkomende maatregelen",
          <Tooltip
            id="commonMaintMeasures"
            text="Maatregelen die in 50% tot 80% van de projecten op grond waarvan de gemiddelde indicatie is gegeven, zijn toegepast."
          />,
          measures
        );
      case "specificMaintMeasures":
        measures = extractMeasuresFromJSON(
          jsonData["maintenanceMeasures"]["maintenanceMeasures"],
          "Specifiek toegepaste maatregelen"
        );
        return buildMeasuresComponent(
          "Specifiek toegepaste maatregelen",
          <Tooltip
            id="specificMaintMeasures"
            text="Maatregelen die in 10% tot 35% van de projecten op grond waarvan de gemiddelde indicatie is gegeven, zijn toegepast."
          />,
          measures
        );
      default:
        return false;
    }
  } catch {
    return false;
  }
}
