import React from "react";
import styled from "styled-components";
import Button from "./Button";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const ContentDiv = styled.div`
  margin-inline: max(3rem, 50% - 600px/2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  max-height: 75%;
  padding: 1.5rem;
  border-radius: 1.5rem;
  background-color: white;
`;

const TextDiv = styled.div`
  overflow-y: auto;
`;

function Modal({ closeModal }) {
  return (
    <Wrapper>
      <ContentDiv>
        <TextDiv>
          <h2>Toelichting</h2>
          De energiescan wordt berekend op basis van data afkomstig van recent
          gerealiseerde verduurzamingsprojecten van Tsavo. We hebben deze
          verduurzamingsprojecten geanalyseerd om inzicht te krijgen in de
          maatregelen en kosten die bij verschillende woningtypes in
          verschillende situaties zijn uitgevoerd. Op basis van deze analyse is
          per type woongebouw ingeschat welke maatregelen en kosten verwacht
          kunnen worden bij verschillende aanvang labels en gewenste
          energieambitie.
          <br></br>
          <br></br>
          Bij de kosten houden we rekening met correcties voor stijgende
          materiaalprijzen en inflatie. Alle projecten uit het verleden zijn
          voor deze analyse geïndexeerd naar de prijsindex van 2022.
          <br></br>
          <br></br>
          Uit de analyse blijkt dat er een grote spreiding is in maatregelen en
          kosten bij soortgelijke gebouwen. Dat is het gevolg van o.a. verschil
          in startsituatie, ambitie en financiële reikwijdte. Toch zien we ook
          een grootste gemene deler en een bandbreedte in investeringskosten.
          Daarmee geeft de energiescan op basis van de Duurzaamheidsmaatregelen
          Catalogus een bruikbare indicatie van te verwachten maatregelen en
          investeringskosten bij de start van een project.
        </TextDiv>
        <Button text="SLUIT" onClick={() => closeModal()} />
      </ContentDiv>
    </Wrapper>
  );
}

export default Modal;
