import React from "react";
import styled from "styled-components";
import Button from "./Button.js";
import { ReactComponent as Logo } from "./logo.svg";

const Bar = styled.div`
  background-color: white;
  min-height: 80px;
  color: white;
  display: flex;
  align-items: center;
`;

const ChildsWrapper = styled.div`
  margin-left: auto;
  margin-right: 2rem;
`;

const HomePageButton = styled(Button)`
  background-color: var(--orange);
`;

const MarginRight = styled.div`
  margin-right: 2rem;
`;

function Navbar() {
  return (
    <Bar>
      <ChildsWrapper>
        <a href="https://www.tsavo.eu/">
          <HomePageButton text="NAAR TSAVO.EU" />
        </a>
      </ChildsWrapper>
      <MarginRight>
        <a href="/">
          <Logo />
        </a>
      </MarginRight>
    </Bar>
  );
}

export default Navbar;
