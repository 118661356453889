import React from "react";
import styled from "styled-components";

const Input = styled.input`
  background: transparent;
  border: 0;
  border-bottom: 1px solid var(--darkGreen);
  margin-left: 0.75rem;
`;

const InvalidText = styled.p`
  color: var(--invalidYellow);
`;

function TextInput({ children, ...props }) {
  return (
    <>
      <label htmlFor={props.id}>{children[0]}</label>
      <Input {...props} />
      <InvalidText>{children[1]}</InvalidText>
    </>
  );
}

export default TextInput;
