import { useState } from "react";
import React from "react";
import Navbar from "./Navbar.js";
import { IntroPage } from "./pages/Intro.js";
import { TypePage } from "./pages/Type.js";
import { YearBuildPage } from "./pages/YearBuild.js";
import { CurrentLabelPage } from "./pages/CurrentLabel";
import { PreferredLabelPage } from "./pages/PreferredLabel";
import { UnitsPage } from "./pages/Units";
import { ResultPage } from "./pages/Result";
import { DoneMeasuresPage } from "./pages/DoneMeasures";
import { MaintenanceQuestionPage } from "./pages/MaintenanceQuestion.js";
import { MaintenancePage } from "./pages/Maintenance.js";
import { ContactPage } from "./pages/Contact.js";
import LoadingAnimation from "./LoadingAnimation.js";
import Modal from "./Modal.js";

function App() {
  const [formData, setFormData] = useState({
    residenceType: "galerij",
    yearBuild: "",
    currentLabel: "G",
    preferredLabel: "gasloosA",
    nUnits: 1,
    expectedMaintenance: 0,
    estimateMaintenance: true,
    doneMeasures: [],
  });

  const [responseData, setResponseData] = useState(null);

  const [page, setPage] = useState(0);

  const [loading, setLoading] = useState(false);

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const getPage = () => {
    switch (page) {
      case 0:
        return (
          <IntroPage openModal={openModal} switchPage={switchPage}></IntroPage>
        );
      case 1:
        return (
          <TypePage
            switchPage={switchPage}
            formData={formData}
            changeHandler={changeHandler}
          ></TypePage>
        );
      case 2:
        return (
          <YearBuildPage
            switchPage={switchPage}
            formData={formData}
            changeHandler={changeHandler}
          ></YearBuildPage>
        );
      case 3:
        return (
          <CurrentLabelPage
            switchPage={switchPage}
            formData={formData}
            changeHandler={changeHandler}
          ></CurrentLabelPage>
        );
      case 4:
        return (
          <PreferredLabelPage
            switchPage={switchPage}
            formData={formData}
            changeHandler={changeHandler}
          ></PreferredLabelPage>
        );
      case 5:
        return (
          <UnitsPage
            switchPage={switchPage}
            formData={formData}
            changeHandler={changeHandler}
            callDMCAPI={callDMCAPI}
          ></UnitsPage>
        );
      case 6:
        return (
          <ResultPage
            switchPage={switchPage}
            formData={formData}
            responseData={responseData}
            nextButtonText="PRECIEZERE INPUT?"
          ></ResultPage>
        );
      case 7:
        return (
          <DoneMeasuresPage
            switchPage={switchPage}
            formData={formData}
            changeHandler={addOrRemoveMeasures}
            responseData={responseData}
          ></DoneMeasuresPage>
        );
      case 8:
        return (
          <MaintenanceQuestionPage
            switchPage={switchPage}
            changeHandler={changeHandler}
            callDMCAPI={callDMCAPI}
          ></MaintenanceQuestionPage>
        );
      case 9:
        return (
          <MaintenancePage
            switchPage={switchPage}
            formData={formData}
            changeHandler={changeHandler}
            callDMCAPI={callDMCAPI}
          ></MaintenancePage>
        );
      case 10:
        return (
          <ResultPage
            switchPage={switchPage}
            formData={formData}
            responseData={responseData}
            nextButtonText="CONTACT"
          ></ResultPage>
        );
      case 11:
        return <ContactPage switchPage={switchPage}></ContactPage>;
      default:
        return <IntroPage switchPage={switchPage}></IntroPage>;
    }
  };

  function changeHandler(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  // Used by the DoneMeasures page, adds/removes a done measure when a user clicks the checkbox button
  function addOrRemoveMeasures(e) {
    let measure = e.target.value;
    setFormData((prevState) => {
      let doneMeasuresCopy = prevState.doneMeasures;
      let index = doneMeasuresCopy.indexOf(measure);
      if (index > -1) {
        doneMeasuresCopy.splice(index, 1);
      } else {
        doneMeasuresCopy.push(measure);
      }

      return { ...formData, doneMeasures: doneMeasuresCopy };
    });
  }

  async function callDMCAPI(pageNumber) {
    setLoading(true);
    formData.source = "website";
    const response = await fetch(
      `https://ashy-cliff-0b5e23f03.2.azurestaticapps.net/api/SustainabilityCostsMeasuresEstimator`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      }
    );
    setLoading(false);
    if (!response.ok) {
      setPage(0);
    } else {
      var body = await response.json();
      setResponseData(body);
      setPage(pageNumber);
    }
  }

  function switchPage(n) {
    // Skip done measures page if current label is A, B or C
    if ((page === 6 && n === 1) || (page === 8 && n === -1)) {
      if (
        formData.currentLabel === "A" ||
        formData.currentLabel === "B" ||
        formData.currentLabel === "C"
      ) {
        if (n === -1) {
          n = -2;
        } else {
          n = 2;
        }
      }
    }
    setPage(page + n);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Navbar></Navbar>
      {modalIsOpen && <Modal closeModal={closeModal} />}
      {loading && <LoadingAnimation />}
      {getPage()}
    </>
  );
}

export default App;
