import React from "react";
import styled from "styled-components";

const Radio = styled.input.attrs({ type: "radio" })`
  transform: scale(1.1);
  border: 0.15rem solid white;
  accent-color: var(--orange);
`;

const Label = styled.label`
  display: inline-flex;
  margin-left: 1rem;
`;

function RadioInput({ children, ...props }) {
  return (
    <>
      <Radio id={props.id} name={props.name} value={props.value} {...props} />
      <Label htmlFor={props.id}>{children}</Label>
    </>
  );
}

export default RadioInput;
