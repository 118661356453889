import React from "react";
import DualBlockLayout from "../DualBlockLayout.js";
import TextInput from "../inputs/TextInput.js";
import Button from "../Button.js";
import styled from "styled-components";
import onderhoud from "../images/onderhoud.jpg";
import { useState } from "react";
import BaseLayout from "../BaseLayout.js";
import ProgressIndicators from "../ProgressIndicators.js";

const PreviousButton = styled(Button)`
  background-color: var(--lightBlue);
`;

const MaintenanceInput = styled(TextInput)`
  width: 8ch;
`;

function MaintenancePage({ switchPage, formData, changeHandler, callDMCAPI }) {
  const [invalid, setUpdated] = useState("");

  const validateInput = (expectedMaintenance) => {
    if (expectedMaintenance < 0 || expectedMaintenance > 1e6) {
      setUpdated("Moet een positief getal zijn");
    } else {
      callDMCAPI(10);
    }
  };

  return (
    <BaseLayout>
      <ProgressIndicators currentStep={3} totalSteps={3} />
      <DualBlockLayout image={onderhoud}>
        <>
          Is er <b>onderhoud</b> dat uitgevoerd moet worden?
        </>
        <>
          Geef het bedrag aan dat in totaal is vrijgemaakt voor het uitvoeren
          van onderhoud aan het woongebouw en u mee wilt nemen in het
          verduurzamingsproject.
        </>
        <>
          <MaintenanceInput
            id="onderhoud"
            name="expectedMaintenance"
            type="number"
            min={0}
            max={1e6}
            width="8ch"
            onChange={(e) => changeHandler(e)}
            value={formData.expectedMaintenance}
          >
            <>Welk bedrag verwacht u te besteden aan onderhoud? </>
            <>{invalid}</>
          </MaintenanceInput>
        </>
      </DualBlockLayout>
      <>
        <PreviousButton text="VORIGE" onClick={() => switchPage(-1)} />
        <Button
          text="BEREKEN"
          onClick={() => validateInput(formData.expectedMaintenance)}
        />
      </>
    </BaseLayout>
  );
}

export { MaintenancePage };
