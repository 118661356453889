import React from "react";
import styled from "styled-components";

const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AlertIcon = styled.svg`
  circle {
    fill: var(--orange);
  }

  text {
    fill: white;
  }
  min-width: 1.1rem;
`;

const AlertText = styled.p`
  margin-left: 1rem;
`;

function Alert() {
  return (
    <AlertWrapper>
      <AlertIcon width="1.1rem" height="1.1rem">
        <circle cx="0.55rem" cy="0.55rem" r="0.5rem" />
        <text x="50%" y="50%" textAnchor="middle" fontSize="0.7rem" dy=".3em">
          !
        </text>
      </AlertIcon>
      <AlertText>
        <small>
          <b>
            De Energiescan is uitsluitend bedoeld voor het verduurzamen van
            woongebouwen. Voor een individuele kostenindicatie ga bijvoorbeeld
            naar:{" "}
            <a
              href="https://www.verbeterjehuis.nl/wizard/"
              target="_blank"
              rel="noopener noreferrer"
            >
              verbeterjehuis.nl
            </a>
            . Er worden alleen scans gemaakt voor gebouwen met een bouwjaar
            vanaf 1945 omdat daar voldoende data voor beschikbaar is.
          </b>
        </small>
      </AlertText>
    </AlertWrapper>
  );
}

export { Alert };
