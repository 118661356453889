import React from "react";
import Button from "../Button.js";
import styled from "styled-components";
import BaseLayout from "../BaseLayout.js";
import { useState } from "react";
import a from "../images/a.png";
import b from "../images/b.png";
import c from "../images/c.png";
import d from "../images/d.png";
import e from "../images/e.png";
import f from "../images/f.png";
import g from "../images/g.png";
import a_aardgasloos from "../images/a_aardgasloos.png";
import b_aardgasloos from "../images/b_aardgasloos.png";
import { getMeasures } from "../Helpers.js";
import Tooltip from "../Tooltip.js";

const PreviousButton = styled(Button)`
  background-color: var(--lightBlue);
`;

const EnergyButton = styled(Button)`
  background-color: ${(props) =>
    props.active ? "var(--darkGreen)" : "var(--lightGreen)"};
`;
const MeasuresButton = styled(Button)`
  background-color: ${(props) =>
    props.active ? "var(--darkGreen)" : "var(--lightGreen)"};
`;

const ButtonWrapper = styled.div`
  align-content: flex-start;
  flex-wrap: wrap;
  display: flex;
  gap: 1rem;
  margin-bottom: 0.67em;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const ResponsiveWrapper = styled.div`
  display: flex;
  background: linear-gradient(
    white,
    white 50%,
    var(--green) 50%,
    var(--green) 100%
  );
  width: 100%;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    overflow-y: auto;
  }
`;

const InputAndCostsDiv = styled.div`
  position: relative;
  width: 50%;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border: var(--textMargin) solid white;

  @media only screen and (max-width: 900px) {
    width: calc(100% - (2 * var(--textMargin)));
    overflow-y: visible;
  }
`;

const MeasuresDiv = styled.div`
  position: relative;
  width: 50%;
  background-color: var(--green);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border: var(--textMargin) solid var(--green);
  color: var(--darkGreen);

  @media only screen and (max-width: 900px) {
    width: calc(100% - (2 * var(--textMargin)));
    overflow-y: visible;
  }
`;

const EnergyMeasures = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
`;

const MaintenanceMeasures = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
`;

const Title = styled.div`
  min-height: 33%;
  display: inline-table;

  @media only screen and (max-width: 1000px) {
    min-height: auto;
    margin-bottom: 2rem;
  }
`;

const BodyInputAndCosts = styled.div`
  display: flex;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    gap: 3rem;
  }
`;

const ResidenceInfo = styled.div`
  width: 50%;

  @media only screen and (max-width: 900px) {
    width 100%;
  }
`;

const TitlesResidenceInfo = styled.h3`
  margin-top: 0;
  margin-bottom: 3rem;
`;

const InfoBlock = styled.div`
  margin-bottom: 1rem;
`;

const Costs = styled.div`
  width 50%;

  @media only screen and (max-width: 900px) {
    width 100%;
  }
`;

const LabelImage = styled.img`
  height: 2rem;
`;

function ResultPage({ switchPage, formData, responseData, nextButtonText }) {
  const [showMaintenanceMeasures, setShowMaintenanceMeasures] = useState(false);

  const formatter = new Intl.NumberFormat("nl", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  function getLabelImage(label) {
    switch (label) {
      case "A":
        return a;
      case "B":
        return b;
      case "C":
        return c;
      case "D":
        return d;
      case "E":
        return e;
      case "F":
        return f;
      case "G":
        return g;
      case "gasloosA":
        return a_aardgasloos;
      case "gasloosB":
        return b_aardgasloos;
      default:
        return a;
    }
  }

  return (
    <BaseLayout>
      <></>
      <ResponsiveWrapper>
        <InputAndCostsDiv>
          <Title>
            <h1>
              Uw <b>overzicht</b>, verwachte duurzaamheids maatregelen en
              duurzaamheids kosten
            </h1>
          </Title>
          <BodyInputAndCosts>
            <ResidenceInfo>
              <TitlesResidenceInfo>Uw woongebouw</TitlesResidenceInfo>
              <InfoBlock>
                <b>Bouwjaar</b>
                <br></br>
                {formData.yearBuild}
              </InfoBlock>
              <InfoBlock>
                <b>Type woongebouw</b>
                <br></br>
                {formData.residenceType}
              </InfoBlock>
              <InfoBlock>
                <b>Aantal woningen</b>
                <br></br>
                {formData.nUnits}
              </InfoBlock>
              <InfoBlock>
                <b>Huidig energielabel</b>
                <br></br>
                <LabelImage
                  src={getLabelImage(formData.currentLabel)}
                  alt="Huidig energielabel"
                />
              </InfoBlock>
              <InfoBlock>
                <b>Gewenst energielabel</b>
                <br></br>
                <LabelImage
                  src={getLabelImage(formData.preferredLabel)}
                  alt="Gewenst energielabel"
                />
              </InfoBlock>
            </ResidenceInfo>
            <Costs>
              <TitlesResidenceInfo>Gemiddelde kosten</TitlesResidenceInfo>
              <InfoBlock>
                <b>
                  Totale kosten{" "}
                  <Tooltip
                    id="totalCosts"
                    text="De totale gemiddelde kosten indicatie bestaat uit de bouwkosten, bijkomende kosten en btw."
                    black
                  />
                </b>
                <br></br>
                {formatter.format(responseData["totalCosts"])}
              </InfoBlock>
              <InfoBlock>
                <b>
                  Totale verduurzamingskosten{" "}
                  <Tooltip
                    id="totalSustCosts"
                    text="De kosten die zijn gekoppeld aan verduurzamingsmaatregelen."
                    black
                  />
                </b>
                <br></br>
                {formatter.format(responseData["sustainabilityCosts"])}
              </InfoBlock>
              <InfoBlock>
                <b>
                  Totale onderhoudskosten{" "}
                  <Tooltip
                    id="totalMaintCosts"
                    text="De kosten die zijn gekoppeld aan gelijk meegenomen onderhoudsmaatregelen."
                    black
                  />
                </b>
                <br></br>
                {formatter.format(responseData["maintenanceCosts"])}
              </InfoBlock>
              <InfoBlock>
                <b>
                  Totale kosten per woning{" "}
                  <Tooltip
                    id="totalCostsPerUnit"
                    text="De totale gemiddelde kosten indicatie gedeeld door het aantal woningen."
                    black
                  />
                </b>
                <br></br>
                {formatter.format(responseData["totalCosts"] / formData.nUnits)}
              </InfoBlock>
              <InfoBlock>
                <b>
                  In 85% van de projecten minder dan{" "}
                  <Tooltip
                    id="totalCosts85"
                    text="De totale kosten per woning zijn in 85% van de projecten op grond waarvan de gemiddelde indicatie is gegeven minder dan genoemd bedrag."
                    black
                  />
                </b>
                <br></br>
                {formatter.format(responseData["unitCosts85"])}
              </InfoBlock>
            </Costs>
          </BodyInputAndCosts>
        </InputAndCostsDiv>
        <MeasuresDiv>
          <Title>
            <h1>
              Verwachte <b>maatregelen</b>
            </h1>
            <ButtonWrapper>
              <EnergyButton
                text="ENERGETISCH"
                active={!showMaintenanceMeasures}
                onClick={() => setShowMaintenanceMeasures(false)}
              ></EnergyButton>
              <MeasuresButton
                text="ONDERHOUD"
                active={showMaintenanceMeasures}
                onClick={() => setShowMaintenanceMeasures(true)}
              ></MeasuresButton>
            </ButtonWrapper>
          </Title>
          <EnergyMeasures show={!showMaintenanceMeasures}>
            {getMeasures(responseData, "standardSustMeasures")}
            {getMeasures(responseData, "commonSustMeasures")}
            {getMeasures(responseData, "lessCommonSustMeasures")}
            {getMeasures(responseData, "specificSustMeasures")}
            {getMeasures(responseData, "rareSustMeasures")}
          </EnergyMeasures>
          <MaintenanceMeasures show={showMaintenanceMeasures}>
            {getMeasures(responseData, "standardMaintMeasures")}
            {getMeasures(responseData, "commonMaintMeasures")}
            {getMeasures(responseData, "specificMaintMeasures")}
          </MaintenanceMeasures>
        </MeasuresDiv>
      </ResponsiveWrapper>
      <>
        <PreviousButton
          text="OPNIEUW"
          onClick={() => window.location.reload()}
        />
        <Button text={nextButtonText} onClick={() => switchPage(1)} />
      </>
    </BaseLayout>
  );
}

export { ResultPage };
