import React from "react";
import SingleBlockLayout from "../SingleBlockLayout.js";
import Button from "../Button.js";
import styled from "styled-components";
import BaseLayout from "../BaseLayout.js";
import coffee from "../images/coffee.jpg";
import bart from "../images/Tsavo_Bart_Nieuw.png";
import monique from "../images/Tsavo_Monique_Nieuw.png";

const PreviousButton = styled(Button)`
  background-color: var(--lightBlue);
`;

const PersonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;

const PersonsDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;

  @media only screen and (max-width: 900px) {
    margin-top: 2rem;
    gap: 1rem;
  }
`;

const PersonImage = styled.img`
  width: 80px;
`;

function ContactPage({ switchPage }) {
  return (
    <BaseLayout>
      <></>
      <SingleBlockLayout image={coffee}>
        <>
          Een eerste <b>inzicht</b> en nu verder.
        </>
        <>
          U heeft met de energiescan een eerste inzicht gekregen van de
          maatregelen en kosten die komen kijken bij het verduurzamen van uw
          woongebouw. Voor het bepalen van een preciezer maatregelenpakket en
          kostenindicatie is meer informatie nodig. Daarvoor is de eerste stap
          een energie advies waarbij o.a. exacter de energetische kwaliteit
          wordt bepaald. Wilt u meer informatie over de vervolgstappen, download
          dan de brochure{" "}
          <a
            href="https://tsavo.eu/verduurzamen-als-vve"
            target="_blank"
            rel="noopener noreferrer"
          >
            <b>VvE's verduurzamen</b>
          </a>{" "}
          of{" "}
          <a
            href="https://tsavo.eu/verduurzamen-van-huurwoningen"
            target="_blank"
            rel="noopener noreferrer"
          >
            <b>huurwoningen verduurzamen</b>
          </a>
          . Wilt u informatie over een energieadvies, neem dan contact op met
          Bart van der Maat of Monique Egenberger.
          <PersonsDiv>
            <PersonWrapper>
              <PersonImage src={monique} />
              <div>
                <b>Monique Egenberger</b>
                <br></br>
                <a href="mailto:monique.egenberger@tsavo.eu">
                  monique.egenberger@tsavo.eu
                </a>
              </div>
            </PersonWrapper>
            <PersonWrapper>
              <PersonImage src={bart} />
              <div>
                <b>Bart van der Maat</b>
                <br></br>
                <a href="mailto:bart.vandermaat@tsavo.eu">
                  bart.vandermaat@tsavo.eu
                </a>
              </div>
            </PersonWrapper>
          </PersonsDiv>
        </>
      </SingleBlockLayout>
      <PreviousButton text="VORIGE" onClick={() => switchPage(-1)} />
    </BaseLayout>
  );
}

export { ContactPage };
