import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: ${(props) => Math.round(props.totalSteps * (window.innerWidth / 7))}px;
  max-width: ${(props) => Math.round(props.totalSteps * 100)}px;
  margin-right: 3rem;
  margin-left: 3rem;
  display: flex;
  align-items: center;
`;

const Line = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  &:before {
    content: "";
    display: block;
    border-top: 0.25rem solid rgb(0, 171, 194);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 0;
  }

  &:after {
    content: "";
    display: block;
    border-top: 0.25rem solid rgb(0, 86, 97);
    position: absolute;
    top: 50%;
    width: ${(props) =>
      Math.round(((props.currentStep - 1) / (props.totalSteps - 1)) * 100)}%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
  }
`;

const Circle = styled.div`
  color: white;
  font-size: 1.7rem;
  background-color: ${(props) =>
    props.primary ? "var(--darkBlue)" : "var(--lightBlue)"};
  height: 2.9rem;
  border-radius: 50%;
  width: 2.9rem;
  line-height: 2.9rem;
  position: relative;
  z-index: 2;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 600px) {
    height: 2.2rem;
    width: 2.2rem;
    font-size: 1.2rem;
  }
`;

function ProgressIndicators({ children, ...props }) {
  const buildSteps = (currentStep, totalSteps) => {
    let content = [];
    for (let i = 1; i <= totalSteps; i++) {
      if (i <= currentStep) {
        content.push(
          <Circle key={i} primary>
            {i}
          </Circle>
        );
      } else {
        content.push(<Circle key={i}>{i}</Circle>);
      }
    }
    return content;
  };

  return (
    <Wrapper {...props}>
      <Line {...props}>{buildSteps(props.currentStep, props.totalSteps)}</Line>
    </Wrapper>
  );
}

export default ProgressIndicators;
