import React from "react";
import styled from "styled-components";
import SingleBlockLayout from "../SingleBlockLayout.js";
import Button from "../Button.js";
import BaseLayout from "../BaseLayout.js";
import coffee from "../images/coffee.jpg";
import { Alert } from "../Alert.js";

const MoreInfoLink = styled.span`
  cursor: pointer;
  color: var(--lightBlue);
`;

function IntroPage({ openModal, switchPage }) {
  return (
    <BaseLayout>
      <></>
      <SingleBlockLayout image={coffee}>
        <>
          Welkom bij de <b>energiescan</b> woongebouwen van <b>Tsavo</b>
        </>
        <>
          De energiescan kunt u zelf maken en geeft een eerste beeld van
          mogelijke duurzaamheidsmaatregelen en investeringskosten. Dit beeld
          wordt bepaald door uw invoergegevens te koppelen met de
          Duurzaamheidsmaatregelen Catalogus van Tsavo. In die catalogus zijn
          maatregelen en kosten opgenomen op basis van meer dan 10.000 recent
          verduurzaamde woningen.
          <br></br>
          <br></br>
          Het maken van een energiescan geeft een eerste beeld. Tsavo gebruikt
          de energiescan bij analyses van woningportefeuilles en bij de start
          van projecten. De energiescan helpt het gesprek over wensen en eisen
          en vormt een basis om een meer specifiek energieadvies te maken, stap
          2 van ons verduurzamingsproces.{" "}
          <MoreInfoLink onClick={() => openModal()}>
            <b>meer info</b>
          </MoreInfoLink>
          <br></br>
          <br></br>
          Op de volgende schermen wordt gevraagd informatie in te vullen over
          het woongebouw en de verduurzamingsambitie. Op basis van deze input
          geven wij een eerste verduurzamingsinzicht waarin we een indicatie van
          de kosten vermelden en een set van mogelijke maatregelen.
          <Alert />
        </>
      </SingleBlockLayout>
      <Button text="BEGIN" onClick={() => switchPage(1)} />
    </BaseLayout>
  );
}

export { IntroPage };
