import React from "react";
import styled from "styled-components";

const StyledBody = styled.div`
  margin: 2rem 0 0 0;
`;

function Body({ children }) {
  return <StyledBody>{children}</StyledBody>;
}

export default Body;
