import React from "react";
import styled from "styled-components";

const ProgressDiv = styled.div`
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 600px) {
    min-height: 70px;
  }
`;

const ContentDiv = styled.div`
  height: 100%;
  display: flex;
  margin: 0 auto;
  margin-inline: max(1.5rem, 50% - 1200px/2);
  border-radius: 1.5rem;
  -webkit-transform: translateZ(0);
  overflow: hidden;
`;

const ButtonsDiv = styled.div`
  min-height: 100px;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 600px) {
    min-height: 70px;
  }
`;

function BaseLayout({ children, ...props }) {
  return (
    <>
      <ProgressDiv>{children[0]}</ProgressDiv>
      <ContentDiv>{children[1]}</ContentDiv>
      <ButtonsDiv>{children[2]}</ButtonsDiv>
    </>
  );
}

export default BaseLayout;
