import React from "react";
import styled from "styled-components";

const StyledTitle = styled.h1`
  margin-bottom: auto;
  word-wrap: break-word;
`;

function Title({ children }) {
  return <StyledTitle>{children}</StyledTitle>;
}

export default Title;
