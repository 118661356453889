import React from "react";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";

const QuestionMark = styled.svg`
  circle {
    fill: ${(props) => (props.black ? "black" : "var(--lightGreen)")};
  }

  text {
    fill: ${(props) => (props.black ? "white" : "var(--green)")};
  }

  cursor: pointer;
`;

const StyledTooltip = styled(Tooltip)`
  font-family: "Roboto";
  font-weight: normal;
  font-size: 1rem;
  color: white;
  border-radius: 1rem;
  width: 300px;
  max-width: 50%;
`;

function CustomTooltip({ children, ...props }) {
  return (
    <>
      <QuestionMark
        width="1rem"
        height="1rem"
        data-tooltip-id={props.id}
        data-tooltip-content={props.text}
        {...props}
      >
        <circle cx="0.5rem" cy="0.5rem" r="0.5rem" />
        <text x="50%" y="50%" textAnchor="middle" fontSize="0.7rem" dy=".4em">
          ?
        </text>
      </QuestionMark>
      <StyledTooltip id={props.id} />
    </>
  );
}

export default CustomTooltip;
