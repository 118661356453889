import React from "react";
import styled from "styled-components";

const Btn = styled.button`
  background-color: var(--darkBlue);
  height: 3rem;
  border: none;
  padding: 1rem;
  border-radius: 0.375rem;
  font-weight: 700;
  letter-spacing: 2px;
  cursor: pointer;

  &:hover {
    background-image: linear-gradient(rgb(0 0 0/10%) 0 0);
  }
`;

const Text = styled.span`
  color: white;
  font-size: 0.8rem;
`;

function Button({ children, ...props }) {
  return (
    <Btn {...props}>
      <Text>{props.text}</Text>
    </Btn>
  );
}

export default Button;
