import React from "react";
import DualBlockLayout from "../DualBlockLayout.js";
import RadioInput from "../inputs/RadioInput.js";
import Button from "../Button.js";
import styled from "styled-components";
import label_huidig from "../images/label_huidig.jpg";
import BaseLayout from "../BaseLayout.js";
import ProgressIndicators from "../ProgressIndicators.js";
import a from "../images/a.png";
import b from "../images/b.png";
import c from "../images/c.png";
import d from "../images/d.png";
import e from "../images/e.png";
import f from "../images/f.png";
import g from "../images/g.png";

const PreviousButton = styled(Button)`
  background-color: var(--lightBlue);
`;

const LabelImage = styled.img`
  height: 2rem;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ResponsiveWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
`;

function CurrentLabelPage({ switchPage, formData, changeHandler }) {
  return (
    <BaseLayout>
      <ProgressIndicators currentStep={3} totalSteps={5} />
      <DualBlockLayout image={label_huidig}>
        <>
          Welk <b>energielabel</b> heeft het <b>woongebouw</b>?
        </>
        <>
          Als een gebouw meerdere woningen heeft, voert u het gemiddelde
          energielabel van het gebouw in. U kunt een energielabel invoeren dat
          is afgemeld volgens de Energie-Index of volgens de NTA-8800. Zie{" "}
          <a
            href="https://www.rvo.nl/files/file/2020/08/infographic-vernieuwd-energielabel-woningen-en-gebouwen-nta-8800.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <b>RVO</b>
          </a>{" "}
          voor meer informatie over deze methodes.
        </>
        <>
          <ResponsiveWrapper>
            <div>
              <InputWrapper>
                <RadioInput
                  id="a"
                  name="currentLabel"
                  value="A"
                  onChange={(e) => changeHandler(e)}
                  checked={formData.currentLabel === "A"}
                >
                  <LabelImage src={a} alt="Label A" />
                </RadioInput>
              </InputWrapper>
              <br></br>
              <InputWrapper>
                <RadioInput
                  id="b"
                  name="currentLabel"
                  value="B"
                  onChange={(e) => changeHandler(e)}
                  checked={formData.currentLabel === "B"}
                >
                  <LabelImage src={b} alt="Label B" />
                </RadioInput>
              </InputWrapper>
              <br></br>

              <InputWrapper>
                <RadioInput
                  id="c"
                  name="currentLabel"
                  value="C"
                  onChange={(e) => changeHandler(e)}
                  checked={formData.currentLabel === "C"}
                >
                  <LabelImage src={c} alt="Label C" />
                </RadioInput>
              </InputWrapper>
              <br></br>

              <InputWrapper>
                <RadioInput
                  id="d"
                  name="currentLabel"
                  value="D"
                  onChange={(e) => changeHandler(e)}
                  checked={formData.currentLabel === "D"}
                >
                  <LabelImage src={d} alt="Label D" />
                </RadioInput>
              </InputWrapper>
              <br></br>
            </div>
            <div>
              <InputWrapper>
                <RadioInput
                  id="e"
                  name="currentLabel"
                  value="E"
                  onChange={(e) => changeHandler(e)}
                  checked={formData.currentLabel === "E"}
                >
                  <LabelImage src={e} alt="Label E" />
                </RadioInput>
              </InputWrapper>
              <br></br>

              <InputWrapper>
                <RadioInput
                  id="f"
                  name="currentLabel"
                  value="F"
                  onChange={(e) => changeHandler(e)}
                  checked={formData.currentLabel === "F"}
                >
                  <LabelImage src={f} alt="Label F" />
                </RadioInput>
              </InputWrapper>
              <br></br>

              <InputWrapper>
                <RadioInput
                  id="g"
                  name="currentLabel"
                  value="G"
                  onChange={(e) => changeHandler(e)}
                  checked={formData.currentLabel === "G"}
                >
                  <LabelImage src={g} alt="Label G" />
                </RadioInput>
              </InputWrapper>
            </div>
          </ResponsiveWrapper>
        </>
      </DualBlockLayout>
      <>
        <PreviousButton text="VORIGE" onClick={() => switchPage(-1)} />
        <Button text="VOLGENDE" onClick={() => switchPage(1)} />
      </>
    </BaseLayout>
  );
}

export { CurrentLabelPage };
