import React from "react";
import DualBlockLayout from "../DualBlockLayout.js";
import TextInput from "../inputs/TextInput.js";
import Button from "../Button.js";
import styled from "styled-components";
import eenheden from "../images/eenheden.jpg";
import { useState } from "react";
import BaseLayout from "../BaseLayout.js";
import ProgressIndicators from "../ProgressIndicators.js";

const PreviousButton = styled(Button)`
  background-color: var(--lightBlue);
`;

const UnitsInput = styled(TextInput)`
  width: 8ch;
`;

function UnitsPage({ switchPage, formData, changeHandler, callDMCAPI }) {
  const [invalid, setUpdated] = useState("");

  const validateInput = (nUnits) => {
    if (nUnits < 1 || nUnits > 1e6) {
      setUpdated("Moet groter zijn dan 0");
    } else {
      callDMCAPI(6);
    }
  };

  return (
    <BaseLayout>
      <ProgressIndicators currentStep={5} totalSteps={5} />
      <DualBlockLayout image={eenheden}>
        <>
          Hoeveel <b>wooneenheden</b> heeft het <b>woongebouw</b>?
        </>
        <>
          De kosten voor het verduurzamen worden onder andere bepaald op basis
          van het aantal wooneenheden in het complex. Geef het aantal
          wooneenheden aan die u in totaal wilt laten verduurzamen.
        </>
        <>
          <UnitsInput
            id="eenheden"
            name="nUnits"
            type="number"
            min={1}
            max={1e6}
            width="8ch"
            onChange={(e) => changeHandler(e)}
            value={formData.nUnits}
          >
            <>Vul het aantal wooneenheden in:</>
            <>{invalid}</>
          </UnitsInput>
        </>
      </DualBlockLayout>
      <>
        <PreviousButton text="VORIGE" onClick={() => switchPage(-1)} />
        <Button text="BEREKEN" onClick={() => validateInput(formData.nUnits)} />
      </>
    </BaseLayout>
  );
}

export { UnitsPage };
