import React from "react";
import styled from "styled-components";
import ContentImageLayout from "./ContentImageLayout.js";
import Title from "./Title.js";
import Body from "./Body.js";

const TextDiv = styled.div`
  display: flex;
  height: calc(50% - (2 * var(--textMargin)));
  flex-direction: column;
  background-color: white;
  overflow-y: auto;
  border: var(--textMargin) solid white;

  @media only screen and (max-width: 900px) {
    height: auto;
    overflow-y: visible;
  }
`;

const InputDiv = styled.div`
  color: var(--darkGreen);
  height: calc(50% - (3rem + var(--textMargin)));
  background-color: var(--green);
  overflow-y: auto;
  border: solid var(--green);
  border-width: 3rem var(--textMargin) var(--textMargin) var(--textMargin);
  font-weight: 700;

  @media only screen and (max-width: 900px) {
    height: auto;
    overflow-y: visible;
    border-width: var(--textMargin);
  }
`;

const ArrowDown = styled.svg`
  position: absolute;
  top: calc(50% - 1px);
  left: var(--textMargin);

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

function DualBlockLayout({ children, ...props }) {
  return (
    <>
      <ContentImageLayout image={props.image} dual>
        <TextDiv>
          <Title>{children[0]}</Title>
          <Body>{children[1]}</Body>
        </TextDiv>
        <ArrowDown fill="white" width="50" height="25">
          <polygon points="0 0,50 0,25 25"></polygon>
        </ArrowDown>
        <InputDiv>{children[2]}</InputDiv>
      </ContentImageLayout>
    </>
  );
}

export default DualBlockLayout;
