import React from "react";
import DualBlockLayout from "../DualBlockLayout.js";
import CheckBoxInput from "../inputs/CheckBoxInput.js";
import { extractMeasuresFromJSON } from "../Helpers.js";
import Button from "../Button.js";
import styled from "styled-components";
import maatregelen from "../images/maatregelen.jpg";
import BaseLayout from "../BaseLayout.js";
import ProgressIndicators from "../ProgressIndicators.js";

const PreviousButton = styled(Button)`
  background-color: var(--lightBlue);
`;

function DoneMeasuresPage({
  switchPage,
  formData,
  responseData,
  changeHandler,
}) {
  // Build the list of estimated sustainability measures based on API response
  function getSustMeasures() {
    const standardSustMeasures = extractMeasuresFromJSON(
      responseData["measures"]["sustainabilityMeasures"],
      "Standaard maatregelen aanpak"
    );
    const commonSustMeasures = extractMeasuresFromJSON(
      responseData["measures"]["sustainabilityMeasures"],
      "Veel voorkomende maatregelen"
    );
    const lessCommonSustMeasures = extractMeasuresFromJSON(
      responseData["measures"]["sustainabilityMeasures"],
      "Regelmatig voorkomende maatregelen"
    );
    const specificSustMeasures = extractMeasuresFromJSON(
      responseData["measures"]["sustainabilityMeasures"],
      "Specifiek toegepaste maatregelen"
    );
    const rareSustMeasures = extractMeasuresFromJSON(
      responseData["measures"]["sustainabilityMeasures"],
      "Zelden tot nooit toegepaste maatregelen"
    );

    const measures = standardSustMeasures.concat(
      commonSustMeasures,
      lessCommonSustMeasures,
      specificSustMeasures,
      rareSustMeasures
    );

    let measuresComponents = [];
    measures.forEach(function (measure, i) {
      measuresComponents.push(
        <React.Fragment key={i}>
          <CheckBoxInput
            id={i}
            name="doneMeasures"
            value={measure}
            onChange={(e) => changeHandler(e)}
            checked={formData.doneMeasures.includes(measure)}
          >
            {measure}
          </CheckBoxInput>
        </React.Fragment>
      );
    });

    return <>{measuresComponents}</>;
  }

  return (
    <BaseLayout>
      <ProgressIndicators currentStep={1} totalSteps={3} />
      <DualBlockLayout image={maatregelen}>
        <>
          Welke <b>duurzaamheidsmaatregelen</b> zijn al uitgevoerd?
        </>
        <>
          Volgens onze automatische analyse zijn de onderstaande mogelijke
          maatregelen van toepassing bij het verduurzamen van uw woongebouw.
          Vink aan wat al gedaan is, dan trekken wij de geschatte kosten van het
          totaalbedrag af.
        </>
        {getSustMeasures()}
      </DualBlockLayout>
      <>
        <PreviousButton text="VORIGE" onClick={() => switchPage(-1)} />
        <Button text="VOLGENDE" onClick={() => switchPage(1)} />
      </>
    </BaseLayout>
  );
}

export { DoneMeasuresPage };
