import React from "react";
import styled from "styled-components";

const ContentDiv = styled.div`
  position: relative;
  width: 50%;
  background: ${(props) =>
    props.dual
      ? "linear-gradient(white,white 50%,var(--green) 50%,var(--green) 100%)"
      : "white"};
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 900px) {
    width: 100%;
    overflow-y: auto;
  }
`;

const ImageDiv = styled.div`
  width: 50%;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

function ContentImageLayout({ children, ...props }) {
  return (
    <>
      <ContentDiv {...props}>{children}</ContentDiv>
      <ImageDiv {...props} />
    </>
  );
}

export default ContentImageLayout;
