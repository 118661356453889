import React from "react";
import styled from "styled-components";
import ContentImageLayout from "./ContentImageLayout.js";
import Title from "./Title.js";
import Body from "./Body.js";

const ContentDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border: var(--textMargin) solid white;
`;

function SingleBlockLayout({ children, ...props }) {
  return (
    <>
      <ContentImageLayout image={props.image}>
        <ContentDiv>
          <Title>{children[0]}</Title>
          <Body>{children[1]}</Body>
        </ContentDiv>
      </ContentImageLayout>
    </>
  );
}

export default SingleBlockLayout;
