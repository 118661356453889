import React from "react";
import DualBlockLayout from "../DualBlockLayout.js";
import RadioInput from "../inputs/RadioInput.js";
import Button from "../Button.js";
import styled from "styled-components";
import label_gewenst from "../images/label_gewenst.jpg";
import BaseLayout from "../BaseLayout.js";
import ProgressIndicators from "../ProgressIndicators.js";
import a from "../images/a.png";
import b from "../images/b.png";
import a_aardgasloos from "../images/a_aardgasloos.png";
import b_aardgasloos from "../images/b_aardgasloos.png";

const PreviousButton = styled(Button)`
  background-color: var(--lightBlue);
`;

const LabelImage = styled.img`
  height: 2rem;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

function PreferredLabelPage({ switchPage, formData, changeHandler }) {
  return (
    <BaseLayout>
      <ProgressIndicators currentStep={4} totalSteps={5} />
      <DualBlockLayout image={label_gewenst}>
        <>
          Wat is het <b>wenselijke</b> energielabel van het <b>woongebouw</b>?
        </>
        <>
          Energielabel B is het minimale energielabel waarnaar verduurzaamd kan
          worden en is vergelijkbaar met de “Standaard”. Met Energielabel A
          wordt een extra stap gemaakt van waaruit het makkelijker is om met het
          woongebouw over te stappen naar een aardgasvrij alternatief. Wanneer u
          direct naar aardgasvrij verwarmen wilt gaan, kiest u voor de ambitie
          aardgasloos.
        </>
        <>
          {formData.currentLabel !== "A" && (
            <>
              <InputWrapper>
                <RadioInput
                  id="a"
                  name="preferredLabel"
                  value="A"
                  onChange={(e) => changeHandler(e)}
                  checked={formData.preferredLabel === "A"}
                >
                  <LabelImage src={a} alt="Label A" />
                </RadioInput>
              </InputWrapper>
              <br></br>{" "}
            </>
          )}
          {formData.currentLabel !== "A" && formData.currentLabel !== "B" && (
            <>
              <InputWrapper>
                <RadioInput
                  id="b"
                  name="preferredLabel"
                  value="B"
                  onChange={(e) => changeHandler(e)}
                  checked={formData.preferredLabel === "B"}
                >
                  <LabelImage src={b} alt="Label B" />
                </RadioInput>
              </InputWrapper>
              <br></br>
            </>
          )}
          <InputWrapper>
            <RadioInput
              id="labelGasloosB"
              name="preferredLabel"
              value="gasloosB"
              onChange={(e) => changeHandler(e)}
              checked={formData.preferredLabel === "gasloosB"}
            >
              <LabelImage src={b_aardgasloos} alt="Label B aardgasloos" />
            </RadioInput>
          </InputWrapper>

          <br></br>
          <InputWrapper>
            <RadioInput
              id="labelGasloosA"
              name="preferredLabel"
              value="gasloosA"
              onChange={(e) => changeHandler(e)}
              checked={formData.preferredLabel === "gasloosA"}
            >
              <LabelImage src={a_aardgasloos} alt="Label A aardgasloos" />
            </RadioInput>
          </InputWrapper>
        </>
      </DualBlockLayout>
      <>
        <PreviousButton text="VORIGE" onClick={() => switchPage(-1)} />
        <Button text="VOLGENDE" onClick={() => switchPage(1)} />
      </>
    </BaseLayout>
  );
}

export { PreferredLabelPage };
